<template>
  <div class="content-box">
    <el-dialog
      :title="type === 'add' ? '添加荣誉' : '编辑荣誉'"
      :visible="is_show"
      width="500px"
      class="dialog-vertical"
      @close="close()"
      @open="openDialogFn"
    >
      <el-form
        size="medium"
        class="medium-form"
        :model="form"
        :rules="rules"
        label-width="90px"
        ref="form"
        @submit.native.prevent="() => {}"
      >
        <el-form-item prop="image" label="图片：">
          <SingleMediaWall ratio="150:115" v-model="form.image" :width="150" :height="115">
            <template #info>
              <div class="info">
                <i class="el-icon-info"></i>建议尺寸为150*115px
              </div>
            </template>
          </SingleMediaWall>
        </el-form-item>
        <el-form-item prop="name" label="名称：">
          <el-input v-model="form.name" placeholder="请输入"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button @click="close()" size="small">取消</el-button>
        <el-button
          type="primary"
          :loading="loading"
          size="small"
          @click="submit()"
          >确定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import SingleMediaWall from "@/modules/common/components/SingleMediaWall";

export default {
  model: {
    prop: "open",
    event: "changeOpen",
  },
  props: {
    // 弹窗配置
    open: {
      type: Boolean,
      detault: false,
    },
    currentHonour: {
      type: Object,
      default: () => {},
    },
  },
  components: { SingleMediaWall },
  created() {},
  computed: {
    // 显示框
    is_show: {
      get() {
        return this.$props.open;
      },
      set(val) {
        this.$emit("changeOpen", val);
      },
    },
  },
  watch: {
    "form.id"(newVal) {
      this.form.user_id = newVal;
    },
  },

  data() {
    return {
      form: {
        image: "",
      },
      rules: {
        image: [{ required: true, message: "请选择图片", trigger: "blur" }],
        name: [{ required: true, message: "请输入名称", trigger: "blur" }],
      },
      loading: false,
      //   添加还是编辑
      type: "add",
    };
  },
  methods: {
    openDialogFn() {
      this.form = JSON.parse(JSON.stringify(this.$props.currentHonour));
      if (this.form.name) {
        this.type = "edit";
      } else {
        this.type = "add";
      }
    },

    close() {
      this.form = {
        name: "",
        image: "",
      };
      this.is_show = false;
    },
    submit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (this.type === "add") {
            this.$emit("addHonourSuccess", this.form);
          } else {
            this.$emit("editHonourSuccess", this.form);
          }
          this.close();
        } else {
          console.log("error submit!!");
          this.close();

          return false;
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped></style>
