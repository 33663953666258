<template>
  <div>
    <el-button type="primary" @click="handleAddHonour">添加</el-button>
    <el-table
      id="sortTable2"
      row-key="name"
      :data="modelList"
      class="thead-light"
      stripe
      :style="{ marginTop: '10px' }"
    >
      <el-table-column
        v-for="(item, index) in sortColumn"
        :key="index"
        :label="item.label"
        :prop="item.prop"
        :min-width="item.min_width"
        :sortable="item.sortable"
      >
        <template slot-scope="scope">
          <!--排序 -->
          <div v-if="item.prop === 'sort'">
            <i class="el-icon-sort"></i>
          </div>

          <!-- 头像 -->
          <list-image
            v-else-if="item.prop === 'image'"
            :src="scope.row[item.prop]"
            borderRadius="50%"
            fit="cover"
          ></list-image>
          <span v-else>{{ scope.row[item.prop] | placeholder }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="120" fixed="right">
        <template slot-scope="scope">
          <el-button
            style="color: #3576ff"
            size="small"
            type="text"
            @click="handleEditHonour(scope.row,scope.$index)"
            >编辑
          </el-button>
          <el-button
            style="color: #3576ff"
            size="small"
            type="text"
            @click="handleDelHonour(scope.row)"
            >删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <AddHonourDialog
      v-model="openAddHonour"
      @addHonourSuccess="addHonourSuccess"
      @editHonourSuccess="editHonourSuccess"
      :currentHonour="currentHonour"
    ></AddHonourDialog>
  </div>
</template>

<script>
import AddHonourDialog from "./AddHonourDialog";
import ListImage from "@/base/components/List/ListImage";
import Sortable from "sortablejs/modular/sortable.core.esm.js";

export default {
  components: { AddHonourDialog, ListImage },
  model: {
    prop: "sortList",
    event: "changeSortList",
  },
  props: {
    sortList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      sortColumn: [
        { label: "拖拽排序", prop: "sort", min_width: 100 },
        { label: "图片", prop: "image", min_width: 100 },
        { label: "名称", prop: "name", min_width: 100 },
      ],
      // 会员弹窗控制
      openAddHonour: false,
      //   当前编辑的会员
      currentHonour: {
        name: "",
        image: "",
      },
      currentI: 0,
    };
  },
  computed: {
    modelList: {
      get() {
        return this.$props.sortList;
      },
      set(val) {
        this.$emit("changeSortList", val);
      },
    },
  },
  methods: {
    //初始化拖拽表格
    initSortable() {
      this.$nextTick(() => {
        const sortTable = document.querySelector(
          "#sortTable2 .el-table__body>tbody"
        );
        console.log(sortTable);
        this.$sortTable2 = Sortable.create(sortTable, {
          animation: 150,
          ghostClass: "sortable-ghost",
          onEnd: (event) => {
            console.log(event.oldIndex, event.newIndex);
            if (event.oldIndex === event.newIndex) {
              return;
            }
            // 拿到拖拽的item
            const oldItem = this.modelList[event.oldIndex];
            // 删除原本位置
            this.modelList.splice(event.oldIndex, 1);
            // 添加到新的位置
            this.modelList.splice(event.newIndex, 0, oldItem);
          },
        });
        //阻止火狐拖拽新建新页面
        document.body.addEventListener("drop", this.preventDrop, false);
      });
    },
    //销毁拖拽表格
    destroySortable() {
      if (this.$sortTable2) this.$sortTable2.destroy();
      document.body.removeEventListener("drop", this.preventDrop, false);
    },
    //阻止默认drop事件
    preventDrop(event) {
      event.preventDefault();
      event.stopPropagation();
    },

    handleEditHonour(row,i) {
      this.currentHonour = row;
      this.openAddHonour = true;
      this.currentI = i
    },
    handleAddHonour() {
      this.currentHonour = {
        name: "",
        image: "",
      };
      this.openAddHonour = true;
    },
    // 删除此荣誉
    handleDelHonour(row) {
      this.$confirm("是否删除此荣誉?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let index = this.modelList.findIndex(
            (item) => item.name === row.name
          );
          this.modelList.splice(index, 1);
          this.$message({
            type: "success",
            message: "删除成功!",
          });
        })
        .catch(() => {});
    },
    // 添加成功
    addHonourSuccess(form) {
      this.modelList.push(form);
    },
    // 编辑成功
    editHonourSuccess(form) {
      console.log(form);

      this.$set(this.modelList, this.currentI, form);
      console.log(this.modelList);
    },
  },
  mounted() {
    this.initSortable();
  },
  beforeDestroy() {
    //销毁拖拽表格
    this.destroySortable();
  },
};
</script>

<style lang="scss" scoped></style>
