export default {
  mounted() {
    window.addEventListener('beforeunload', this._beforeunloadHandler)
  },
  methods: {
    _beforeunloadHandler(event) {
      const isEdit = JSON.stringify(this.form) !==
      JSON.stringify(this.$props.formData[this.key])
      if (isEdit) {
        event.preventDefault()
        // Chrome requires returnValue to be set.
        event.returnValue = '您在页面编辑了未保存，是否确认离开'
        return '您在页面编辑了未保存，是否确认离开'
      }
    },
  },
  beforeDestroy() {
    window.removeEventListener('beforeunload', this._beforeunloadHandler)
  },
  beforeRouteLeave(to, form, next) {
    const isEdit =
      JSON.stringify(this.form) !==
      JSON.stringify(this.$props.formData[this.key])
    if (isEdit) {
      // *** 此处必须要加延迟执行、不加的话点击浏览器按钮时会存在问题，弹框一闪而过，
      // 页面没有变，但是地址已经变了
      setTimeout(() => {
        this.$msgbox
          .confirm('当前页面有修改，是否放弃更改？', '提示', {
            type: 'warning',
            distinguishCancelAndClose: true,
            confirmButtonText: '保存',
            cancelButtonText: '确定',
          })
          .then((res) => {
            this.saveConfig()
            next()
          })
          .catch(async (action) => {
            this.cancel()
            next()
          })
      }, 100)
    } else {
      next()
    }
  },
}
